import React, {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {Helmet} from "react-helmet";
import {useDataState, useDataDispatch} from "../../state/context";
import  useAsyncDataFetch  from "../Hooks/useAsyncDataFetch";
import {getShop, getMore} from "../../state/actions/ShopAction";
import queryString from 'query-string'
import StickyBox from "react-sticky-box";
import Grid from './grid/index';
import Sidebar from './sidebar/index';
import MobileGrid from './grid/mobile';
import NoFound from '../pages/NoFound'
import styles from './index.module.scss';

const Shop = (props) => {
    const {params} = props.match;
	const location = useLocation();
    const [moreLoading, setMoreLoading] = useState(true);
    const getParams = queryString.parse(props.location.search);
    const {shop, base} = useDataState();
    const {sort, color, discount, size, min_price, max_price, gender, search, attr, scroll, pid} = getParams;
	const [scrollData, setScrollData] = useState(scroll);
    const dispatch = useDataDispatch();
    const {gen, cat, subcat} = params;

	shop.offset = (scrollData) ? scrollData: shop.offset;
    const {isLoading} = useAsyncDataFetch({promiseFn: getShop, dispatch}, base.ssr, {
        gen,
        cat,
        subcat,
        sort,
        color,
        discount,
        size,
        min_price,
        max_price,
        gender,
        search,
        attr,
        limit: scrollData
    });

    useEffect(() => {
        const loadMoreItems = () => {
			if(scrollData) {
                delete getParams.scroll;
                delete getParams.pid;
                setScrollData(false);
                    window.history.replaceState( {} , '', location.pathname + '?' + queryString.stringify({...getParams}));
            }

            if ((!shop.offset && !isLoading) || (shop.offset < shop.total && !isLoading)) {
                const {clientHeight} = (document.getElementById("grid") !== null) ? document.getElementById("grid") : {clientHeight: 0};
                const {scrollTop} = document.documentElement;

                if (scrollTop >= (clientHeight - window.innerHeight) && moreLoading && shop.loadStatus) {
                    setMoreLoading(false);
                    const urlParams = [gen, cat, subcat];
                    const getParams = {
                        attr: attr,
                        sort: sort,
                        color: color,
                        size: size,
                        discount: discount,
                        limit: shop.limit,
                        search: search,
                        gender: gender,
                        offset: parseInt(shop.offset) + parseInt(shop.limit),
                        min_price: min_price,
                        max_price: max_price
                    };

                    getMore({urlParams, getParams, dispatch});
                } else if (scrollTop <= (clientHeight - window.innerHeight) && !moreLoading) {
                    setMoreLoading(true);
                }
            }
        }

        window.addEventListener("scroll", loadMoreItems, {passive: true})

        return () => window.removeEventListener("scroll", loadMoreItems)
    }, [moreLoading, shop.offset, shop.total, isLoading, getParams]);


    if (!base.success) {
        return <NoFound />;
    }

    return (
        <div className={styles.shop}>
          	{(typeof shop.seo.title !== 'undefined' && shop.seo.title[base.currentLang] !== null) ? (
              <Helmet>
                  <title>{shop.seo.title[base.currentLang]}</title>
                  <meta name="description" content={shop.seo.description[base.currentLang]} />
              </Helmet>
          	):null}
          
            {isMobile || base.mobile ? (
                <MobileGrid {...props} products={shop.products} 
                            mobile={base.mobile}
                            total={shop.total}
                            description={null} 
                            productSort={shop.productSort} i
                            isLoading={isLoading}
                            setMoreLoading={setMoreLoading}
                            getParams={getParams}
                            limit={shop.limit}
                            offset={shop.offset}
                            productId={pid}
                />
            ) : (
                <>
                <StickyBox offsetTop={210} offsetBottom={0}>
                    <Sidebar {...props} />
                </StickyBox>
                <Grid {...props} products={shop.products}
                      total={shop.total}
                      description={null} 
                      productSort={shop.productSort} i
                      isLoading={isLoading}
                      setMoreLoading={setMoreLoading}
                      getParams={getParams}
                      limit={shop.limit}
                      lang={base.currentLang}
                      offset={shop.offset}
                      productId={pid}
                />
                </>
            )}
        </div>
    );
};

export default Shop;
