import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Helmet} from "react-helmet";
import constants from "../../../state/constants";
import Last from "./last/index";
import Header from "./header/index";
import Product from './product/index';
import Loader from '../../../ui/loader/index'
import styles from './index.module.scss';

const Grid = (props) => {
	const {t} = useTranslation();
    const {params} = props.match;
    const {gen} = params;

    const productArray = props.products.map((product) => {
      return productMicroData(product, props.lang);
    });

	const jsonLdProducts = {
      "@context": "http://schema.org",
      "@type": "CollectionPage",
      "@id": "",
      "mainEntity": {
        "@type": "ItemList",
        "itemListElement": productArray
      }
    };

	useEffect(()=>{
        const element = document.getElementById(props.productId);
        if (element && !props.isLoading && props.productId) {
            element.scrollIntoView(false);
        }
    }, [props.isLoading]);

    return (
        <div id="grid" className={styles.grid}>
            {gen === 'last' ? (<Last />):null}
            <Header {...props} total={props.total} shopSort={props.productSort} getParams={props.getParams}/>
            {props.isLoading? (
                <Loader />
            ):(
                <>
                {typeof props.products !== 'undefined' && props.products.length > 0 ? (
                    <>
                    {Object.entries(props.products).map(([key, val]) => (
                        <div key={val.id} className={styles.grid_product}>
                            <Product product={val} offset={props.offset} />          					
                        </div>
                    ))}

                    {!props.setMoreLoading? (
                        <Loader />
                    ):null}
                    </>
                ) : (
                    <div className={styles.grid_empty}>{t("products_not_found")}</div>
                )}
                </>
            )}

			{props.description !== null ? (
				<div className={styles.grid_description} dangerouslySetInnerHTML={{ __html: props.description }} />
			):null}

			 <Helmet>
              <script type="application/ld+json">{JSON.stringify(jsonLdProducts)}</script>
            </Helmet>
        </div>
    );
};

function productMicroData(product, lang){
	return {
		"@type": "Product",
		"url": constants.BASE_URL + "/" + lang + product.link,
		"name": product['title_' + lang],
		"category": (product.category !== null) ? product.category['title_' + lang]:"",
		"sku": product.article+product.color_num,
		"image": constants.API_BASE_URL + '/uploads/products/web/200-200' + product.image + '.jpg',
		"brand": {
          "@type": "Brand",
          "name": 'Puma'
        },
		"offers": {
        "@type": "Offer",
        "priceCurrency": "тг",
        "price": product.price,
        "url": "https://sportspirit.md/" + lang + product.link,
        "itemCondition": "http://schema.org/NewCondition",
        "availability": "http://schema.org/InStock"
      }
		
	}
}

export default Grid;
