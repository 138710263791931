import React, {useEffect} from "react";
import {isMobile} from 'react-device-detect';
import "./App.css";
import {DataProvider} from "./state/context";
import Routes from "./routes/index";
import Layout from "./view/layout/index";
import Header from "./view/layout/header";
import Footer from "./view/layout/footer";
import MobileHeader from "./view/layout/mobile/header";
import MobileFooter from "./view/layout/mobile/footer";
import i18n from "i18next";
import {Helmet} from "react-helmet";

const App = props => {
    useEffect(() => {
        // Remove SSR data after ComponentDidMount and i18n loaded
        if (i18n.isInitialized && window.ssrData) {
            window.ssrData = undefined;
        }
    });
    const {data} = props;
	const LocalBusiness = {

    };

    return (
        <DataProvider ssrData={data}>
          	<Helmet>
              <script type="application/ld+json">{JSON.stringify(LocalBusiness)}</script>
            </Helmet>
            {!isMobile && !(typeof data !== 'undefined' && data.data['base']['mobile']) ? (
          		<Header />
            ) : (
          		<MobileHeader />
            )}

            <Layout mobile={(isMobile || (typeof data !== 'undefined' && data.data['base']['mobile']))}>
                <Routes />
            </Layout>

            {!isMobile && !(typeof data !== 'undefined' && data.data['base']['mobile']) ? (
          		<Footer />
            ) : (
                <MobileFooter />
            )}
        </DataProvider>
    );
};

export default App;
