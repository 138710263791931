import React, {useEffect} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import StickyBox from "react-sticky-box";
import {Link} from 'react-router-dom';
import Cookies from "../../../state/coockies/index";
import constants from "../../../state/constants";
import StorageService from "../../../state/storage/index";
import {useDataDispatch} from "../../../state/context";
import {useDataState} from "../../../state/context";
import {showBtnLoader} from "../../../state/actions/FormAction";
import {sendCart} from "../../../state/actions/CartAction";
import Product from './product/index';
import Certificate from './certificate/index';
import Steps from './steps/index';
import Total from '../total/index';
import styles from './index.module.scss';

const Checkout = () => {
    const {t} = useTranslation();
    const {user, cart, base} = useDataState();
	const dispatch = useDataDispatch();

	/*const sendHandler = (e) => {
		e.preventDefault();
		const card = StorageService.get('aBX32gTf');
		const data = new FormData(e.target);
		data.append('lang', base.currentLang);
		data.append('user_id', user.id);
		data.append('card', (typeof card !== 'undefined' && card !== null) ? card.barcode:null);

		showBtnLoader(dispatch, 'sendOrderForm', true);
        sendCart({data, dispatch});
    };*/

	const sendHandler = (e) => {
		e.preventDefault();
		const data = new FormData(e.target);

		if(data.get("payment") == 'card' || data.get("payment") == 'card_2') {
			const formElem = document.getElementById("placeOrder");
			document.getElementById("orderGoods").value = JSON.stringify(StorageService.get('products'));
			document.getElementById("orderCerts").value = JSON.stringify(StorageService.get('certificate'));
			document.getElementById("orderToken").value = Cookies.get('token');

			formElem.setAttribute('action', constants.API_URL + '/order');
			formElem.submit();
		}else{
          data.append('lang', base.currentLang)

          showBtnLoader(dispatch, 'sendOrderForm', true);
          sendCart({data, dispatch});
  		}
    };

	useEffect(() => {
		if (typeof window !== 'undefined' && cart.products !== null && Object.keys(cart.products).length > 0) {
            const productArray = Object.entries(cart.products).map(([key, val]) => {
				return {
                  item_id: val.product.article + val.product.color_num,
                  item_name: val.product['title_' + base.currentLang],
                  coupon: '',
                  discount: 0,
                  affiliation: 'SportSpirit',
                  item_brand: 'Puma',
                  item_category: val.product['gen_title_' + base.currentLang],
                  item_category2: val.product.category[0]['title_' + base.currentLang],
                  item_category3: val.product.category[1]['title_' + base.currentLang],
                  item_variant: val.product.color,
                  item_list_name: 'Checkout',
                  item_list_id: 'checkout',
                  price: val.product.price,
                  currency: "тг",
                  quantity: val.count
				}
            });
        }
    }, [cart.products]);

    return (
        <form onSubmit={sendHandler} className={styles.page} id="placeOrder" method="POST">
          	<input type="hidden" name="lang" value={base.currentLang} />
          	<input type="hidden" name="goods" id="orderGoods" />
          	<input type="hidden" name="certificates" id="orderCerts" />
          	<input type="hidden" name="token" id="orderToken" />
          
          
            <div className={styles.page_header}>
                <div className={styles.page_header_content}>
                    <h1>{t("checkout_title")}</h1>
                    <Link className={styles.page_header_content_link} to={"/" + base.currentLang + "/cart"}>
                        <span className={styles.page_header_content_link_text}>{t("back_to_cart")}</span>
                    </Link>
                </div>
            </div>
            <div className={styles.page_body}>
                <div className={styles.page_body_left}>
                    <Steps />
                </div>
                {isMobile || base.mobile ? (
                    <div className={styles.page_body_right}>

                        {Object.entries(cart.certificates).map(([size, item]) => (
                            <Certificate key={size} size={size} certificat={item} disableRemove={true}/>
                        ))}

                        {Object.entries(cart.products).map(([size, item]) => (
                            <Product key={size} size={size} {...item} disableRemove={true}/>
                        ))}
                        <Total title={false}/>
                    </div>
                ):(
                    <StickyBox offsetTop={100} offsetBottom={0}>
                        <div className={styles.page_body_right}>

                            {Object.entries(cart.certificates).map(([size, item]) => (
                                <Certificate key={size} size={size} certificat={item} disableRemove={true}/>
                            ))}

                            {Object.entries(cart.products).map(([size, item]) => (
                                <Product key={size} size={size} {...item} disableRemove={true}/>
                            ))}
                            <Total title={false}/>
                        </div>
                    </StickyBox>
                )}
            </div>
        </form>
    );
};

export default Checkout