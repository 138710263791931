import React from 'react'
import constants from "../../../state/constants";
import Product from '../../shop/grid/product/index'
import NextArrow from '../../../ui/slider/NextArrow/index'
import PrevArrow from '../../../ui/slider/PrevArrow/index'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './index.module.scss';

const Bannner = (props) => {
    const settings = {
        arrows: true,
        dots: false,
        autoplay: true,
        infinite: false,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 5999,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            }
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    const bannerSettings = {
        arrows: false,
        dots: true,
        autoplay: true,
        infinite: false,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const textSize = ['text-small', 'text-medium', 'text-big', 'text-large']

    function position(data) {
        switch (parseInt(data.position)) {
            case 1:
                return 'left'
            case 2:
                return 'center'
            case 3:
                return 'right'
            default:
                return 'left'
        }
    }

    function vPosition(data) {
        switch (parseInt(data.vposition)) {
            case 1:
                return 'top'
            case 2:
                return 'middle'
            case 3:
                return 'bottom'
            default:
                return 'middle'
        }
    }

    return (
        <>
        {
            props.data.set.length > 1 ?
                <div className={styles.banner}>
                    {typeof props.data.set[0].description !== 'undefined' && props.data.set[0].description['slider'] ? (
                        <Slider {...bannerSettings}>
                            {Object.entries(props.data.set).map(([index, image]) => (
                                <div key={index} className={styles.banner_image}>
                                    <a href={image.description['url_' + props.currentLang]}>
                                        <picture>
                                            <source
                                                srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/webp/1900-' +image.image + '.webp'}
                                                type="image/webp" media="(min-width: 1200px)"/>
                                            <source
                                                srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/web/1900-' + image.image + '.jpg'}
                                                type="image/jpeg" media="(min-width: 1200px)"/>
                                            <source
                                                srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/webp/800-' + image.image_mobile + '.webp'}
                                                type="image/webp" media="(max-width: 1200px)"/>
                                            <source
                                                srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/web/800-' + image.image_mobile + '.jpg'}
                                                type="image/jpeg" media="(max-width: 1200px)"/>
                                            <img loading={props.lazy === '0' ? 'eager' : 'lazy'}
                                                 className={styles.items_item_img}
                                                 alt={image.description['text_' + props.currentLang]}
                                                 src={constants.API_BASE_URL + '/uploads/banners/thumbnails/web/1900-' + image.image + ".jpg"}
                                                 width="1920" height="660"/>
                                        </picture>
                                    </a>

                                    <div className={styles.banner_image_content}
                                         data-horizontal={position(image.description)}
                                         data-vertical={vPosition(image.description)}
                                         data-alignment={position(image.description)}>
                                        <div>
                                            <h5 dangerouslySetInnerHTML={{__html: (image.description['text_' + props.currentLang] !== null) ? image.description['text_' + props.currentLang].replaceAll('  ', '<br />') : ''}}
                                                className={styles[textSize[parseInt(image.description.size) - 1]]}
                                                style={{
                                                    color: image.description.titlecolor,
                                                    fontSize: image.description.title_size + 'px',
                                                    lineHeight: image.description.title_size + 'px'
                                                }}/>
                                            <p dangerouslySetInnerHTML={{__html: (image.description['description_' + props.currentLang] !== null) ? image.description['description_' + props.currentLang].replaceAll('  ', '<br />') : ''}}
                                               className={styles[textSize[parseInt(image.description.size) - 1]]}
                                               style={{
                                                   color: image.description.titlecolor,
                                                   fontSize: image.description.text_size + 'px',
                                                   lineHeight: image.description.text_size + 'px'
                                               }}/>

                                            {image.description['url_' + props.currentLang] !== null && image.description['url_' + props.currentLang] !== '' ? (
                                                <a className={styles.banner_set_content_link}
                                                   style={{
                                                       color: image.description.secondcolor,
                                                       background: image.description.color
                                                   }}
                                                   href={image.description['url_' + props.currentLang]}>
                                                    { image.description['url_text_' + props.currentLang] }
                                                </a>
                                            ) : null}

                                            {image.description['url_2' + props.currentLang] !== null && image.description['url_2' + props.currentLang] !== '' ? (
                                                <a className={styles.banner_set_content_link}
                                                   style={{
                                                       color: image.description.secondcolor,
                                                       background: image.description.color
                                                   }}
                                                   href={image.description['url_2' + props.currentLang]}>
                                                    { image.description['url_text_2' + props.currentLang] }
                                                </a>
                                            ) : null}

                                            {image.description['url_3' + props.currentLang] !== null && image.description['url_3' + props.currentLang] !== '' ? (
                                                <a className={styles.banner_set_content_link}
                                                   style={{
                                                       color: image.description.secondcolor,
                                                       background: image.description.color
                                                   }}
                                                   href={image.description['url_3' + props.currentLang]}>
                                                    { image.description['url_text_3' + props.currentLang] }
                                                </a>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <div className={styles.banner_set}>
                            {Object.entries(props.data.set).map(([index, image]) => (
                                <div key={index} style={{width: 100 / props.data.set.length + "%"}}>
                                    <a href={image.description['url_' + props.currentLang]}>
                                        <picture>
                                            <source
                                                srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/webp/1900-' + image.image + '.webp'}
                                                type="image/webp" media="(min-width: 1200px)"/>
                                            <source
                                                srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/web/1900-' + image.image + '.jpg'}
                                                type="image/jpeg" media="(min-width: 1200px)"/>
                                            <source
                                                srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/webp/800-' + image.image_mobile + '.webp'}
                                                type="image/webp" media="(max-width: 1200px)"/>
                                            <source
                                                srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/web/800-' + image.image_mobile + '.jpg'}
                                                type="image/jpeg" media="(max-width: 1200px)"/>
                                            <img className={styles.items_item_img}
                                                 alt={ image.description['text_' + props.currentLang] }
                                                 src={constants.API_BASE_URL + '/uploads/banners/thumbnails/web/1900-' + image.image + ".jpg"}
                                                 width="1920" height="660"/>
                                        </picture>
                                    </a>
                                    {image.description['text_' + props.currentLang] !== null || image.description['url_' + props.currentLang] !== null ? (
                                        <div className={styles.banner_set_content}>
                                            {image.description['text_' + props.currentLang] !== null ? (
                                                <h5 className={styles.banner_set_content_title}>
                                                    { image.description['text_' + props.currentLang] }
                                                </h5>
                                            ) : null}


                                            {image.description['url_' + props.currentLang] !== null && image.description['url_' + props.currentLang] !== '' ? (
                                                <a className={styles.banner_set_content_link}
                                                   href={image.description['url_' + props.currentLang]}>
                                                    { image.description['url_text_' + props.currentLang] }
                                                </a>
                                            ) : null}
                                            {image.description['url_2' + props.currentLang] !== null && image.description['url_2' + props.currentLang] !== '' ? (
                                                <a className={styles.banner_set_content_link}
                                                   href={image.description['url_2' + props.currentLang]}>
                                                    { image.description['url_text_2' + props.currentLang] }
                                                </a>
                                            ) : null}
                                            {image.description['url_3' + props.currentLang] !== null && image.description['url_3' + props.currentLang] !== '' ? (
                                                <a className={styles.banner_set_content_link}
                                                   href={image.description['url_3' + props.currentLang]}>
                                                    { image.description['url_text_3' + props.currentLang] }
                                                </a>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    )}

                    {Object.entries(props.data.items).map(([indexSlider, slider]) => (
                        slider.length > 0 ?
                            <div key={indexSlider} className={styles.banner_slider}>
                                <Slider {...settings}>
                                    {Object.entries(slider).map(([indexProduct, product]) => (
                                        <Product key={product.id} product={product}/>
                                    ))}
                                </Slider>
                            </div> : null
                    ))}
                </div>
                :
                <div className={styles.banner}>

                    <div className={styles.banner_image}>
                        <a href={props.data.set[0].description['url_' + props.currentLang]}>
                            <picture>
                                <source
                                    srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/webp/1900-' + props.data.set[0].image + '.webp'}
                                    type="image/webp" media="(min-width: 1200px)"/>
                                <source
                                    srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/web/1900-' + props.data.set[0].image + '.jpg'}
                                    type="image/jpeg" media="(min-width: 1200px)"/>
                                <source
                                    srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/webp/800-' + props.data.set[0].image_mobile + '.webp'}
                                    type="image/webp" media="(max-width: 1200px)"/>
                                <source
                                    srcSet={constants.API_BASE_URL + '/uploads/banners/thumbnails/web/800-' + props.data.set[0].image_mobile + '.jpg'}
                                    type="image/jpeg" media="(max-width: 1200px)"/>
                                <img loading={props.lazy === '0' ? 'eager' : 'lazy'} className={styles.items_item_img}
                                     alt={props.data.set[0].description['text_' + props.currentLang]}
                                     src={constants.API_BASE_URL + '/uploads/banners/thumbnails/web/1900-' + props.data.set[0].image + ".jpg"}
                                     width="1920" height="660"/>
                            </picture>
                        </a>

                        <div className={styles.banner_image_content}
                             data-horizontal={position(props.data.set[0].description)}
                             data-vertical={vPosition(props.data.set[0].description)}
                             data-alignment={position(props.data.set[0].description)}>
                            <div>
                                <h5 dangerouslySetInnerHTML={{__html: (props.data.set[0].description['text_' + props.currentLang] !== null) ? props.data.set[0].description['text_' + props.currentLang].replaceAll('  ', '<br />') : ''}}
                                    className={styles[textSize[parseInt(props.data.set[0].description.size) - 1]]}
                                    style={{
                                        color: props.data.set[0].description.titlecolor,
                                        fontSize: props.data.set[0].description.title_size + 'px',
                                        lineHeight: props.data.set[0].description.title_size + 'px'
                                    }}/>
                                <p dangerouslySetInnerHTML={{__html: (props.data.set[0].description['description_' + props.currentLang] !== null) ? props.data.set[0].description['description_' + props.currentLang].replaceAll('  ', '<br />') : ''}}
                                   className={styles[textSize[parseInt(props.data.set[0].description.size) - 1]]}
                                   style={{
                                       color: props.data.set[0].description.titlecolor,
                                       fontSize: props.data.set[0].description.text_size + 'px',
                                       lineHeight: props.data.set[0].description.text_size + 'px'
                                   }}/>

                                {props.data.set[0].description['url_' + props.currentLang] !== null && props.data.set[0].description['url_' + props.currentLang] !== '' ? (
                                    <a className={styles.banner_set_content_link}
                                       style={{
                                           color: props.data.set[0].description.secondcolor,
                                           background: props.data.set[0].description.color
                                       }}
                                       href={props.data.set[0].description['url_' + props.currentLang]}>
                                        { props.data.set[0].description['url_text_' + props.currentLang] }
                                    </a>
                                ) : null}

                                {props.data.set[0].description['url_2' + props.currentLang] !== null && props.data.set[0].description['url_2' + props.currentLang] !== '' ? (
                                    <a className={styles.banner_set_content_link}
                                       style={{
                                           color: props.data.set[0].description.secondcolor,
                                           background: props.data.set[0].description.color
                                       }}
                                       href={props.data.set[0].description['url_2' + props.currentLang]}>
                                        { props.data.set[0].description['url_text_2' + props.currentLang] }
                                    </a>
                                ) : null}

                                {props.data.set[0].description['url_3' + props.currentLang] !== null && props.data.set[0].description['url_3' + props.currentLang] !== '' ? (
                                    <a className={styles.banner_set_content_link}
                                       style={{
                                           color: props.data.set[0].description.secondcolor,
                                           background: props.data.set[0].description.color
                                       }}
                                       href={props.data.set[0].description['url_3' + props.currentLang]}>
                                        { props.data.set[0].description['url_text_3' + props.currentLang] }
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    {Object.entries(props.data.items).map(([indexSlider, slider]) => (
                        slider.length > 0 ?
                            <div key={indexSlider} className={styles.banner_slider}>
                                <Slider {...settings}>
                                    {Object.entries(slider).map(([indexProduct, product]) => (
                                        <Product key={product.id} product={product}/>
                                    ))}
                                </Slider>
                            </div> : null
                    ))}
                </div>
        }
        </>
    )
}

export default Bannner